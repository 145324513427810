
import EntityCard from "./components/EntityCard.vue";

export default {
  components: { EntityCard },

  props: {
    colorBackground: {
      type: String,
      required: true,
    },

    colorSeperators: {
      type: String,
      required: true,
    },

    colorForeground: {
      type: String,
      required: true,
    },

    alignControlsEnd: {
      type: Boolean,
      default: false,
    },
  },
};
